import { apiClient } from '../../apiClient'
import { CreateServiceDetailPayload } from './types'

export const createServiceDetails = async (
  repairOrderId: string,
  serviceDetails: CreateServiceDetailPayload[]
): Promise<void> => {
  return apiClient.post(`/repairOrders/${repairOrderId}/serviceDetails/`, {
    items: serviceDetails,
  })
}
