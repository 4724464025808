import { apiClient } from '../../apiClient'
import { VehicleCatalogQueryOptions } from './types'

export const fetchVehicleCatalogModels = async ({
  year,
  make,
}: VehicleCatalogQueryOptions): Promise<string[]> => {
  const response = await apiClient.get(`/vehicleCatalog/models`, {
    params: {
      year,
      make,
    },
  })
  return response.data as string[]
}
