import { StyleSheet } from 'react-native'

// Styling for the component
export const styles = StyleSheet.create({
  container: {
    width: '100%',
    marginBottom: 15,
  },
  label: {
    fontSize: 16,
    color: 'rgba(14, 14, 15, 1)',
    marginBottom: 0,
    flexShrink: 0, // Prevent shrinking
    alignSelf: 'flex-start', // Ensure width is determined by content
  },
  value: {
    fontSize: 14,
    color: 'rgba(28, 28, 30, .4)',
    paddingLeft: 0,
    paddingTop: 6,
  },
})
