import { Alert, AlertButton, AlertOptions, Platform } from 'react-native'

export const showAlert = (
  title: string,
  message?: string,
  buttons?: AlertButton[],
  options?: AlertOptions
) => {
  if (Platform.OS === 'web') {
    window.alert(`${title}: ${message}`)
  } else {
    Alert.alert(title, message, buttons, options)
  }
}

export const showAlertConfirm = (
  title: string,
  message?: string,
  onConfirm?: () => void,
  onCancel?: () => void,
  options?: AlertOptions
) => {
  const confirmButton: AlertButton = { text: 'Confirm', onPress: onConfirm }
  const cancelButton: AlertButton = {
    text: 'Cancel',
    onPress: onCancel,
    style: 'cancel',
  }

  if (Platform.OS === 'web') {
    const result = window.confirm(`${title}\n\n${message}`)
    if (result && onConfirm) onConfirm()
    else if (!result && onCancel) onCancel()
  } else {
    Alert.alert(title, message, [cancelButton, confirmButton], options)
  }
}
