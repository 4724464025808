import { StyleSheet } from 'react-native'

// Styling for the component
export const styles = StyleSheet.create({
  header: {
    backgroundColor: '#fff',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between', // Space out left, center, and right elements
    paddingHorizontal: 8,
    paddingVertical: 8,
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
  backButton: {
    padding: 5,
  },
  title: {
    fontSize: 18,
    fontWeight: '500',
    textAlign: 'center',
    color: 'rgba(28, 28, 30, 0.68)',
    flex: 1, // Ensures center alignment within the header
  },
  rightPlaceholder: {
    width: 24, // Same width as icon to keep title centered
  },
  content: {
    padding: 20,
    flex: 1,
  },
})
