import { ProfileSummary } from '../fetchCurrentUser'

export enum EmployeeStatus {
  Pending = 'Pending',
  Active = 'Active',
  Rejected = 'Rejected',
}

export type Employee = {
  id: string
  userId: string
  companyId?: string
  profile?: ProfileSummary
  status: EmployeeStatus
  data?: unknown
  createdAt?: Date
}
