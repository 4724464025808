import React, { useState } from 'react'
import { View, Pressable, Text, TouchableOpacity } from 'react-native'
import { MaterialIcons } from '@expo/vector-icons'
import { LabelValue } from '../../../LabelValue'
import { styles } from './styles'
import type { ServiceDetailsInfoProps } from './types'
import { ServiceDetailStatus } from '../../../../../api/adapters/repairOrder/types'

// Shows READ ONLY content of Service details
export function ServiceDetailsInfoComponent({
  serviceDetails,
  action,
  showMechanicInfo = true,
}: ServiceDetailsInfoProps) {
  const [isDetailsCollapsed, setIsDetailsCollapsed] = useState(true)
  return (
    <>
      <View>
        <Pressable
          style={styles.collapsibleHeader}
          onPress={() => setIsDetailsCollapsed(!isDetailsCollapsed)}
        >
          <Text style={styles.collapsibleHeaderLabel}>Service Details</Text>
          <MaterialIcons
            name={isDetailsCollapsed ? 'expand-more' : 'expand-less'}
            size={24}
          />
        </Pressable>
      </View>

      {isDetailsCollapsed &&
        serviceDetails.map((item) => (
          <View key={item.id} style={styles.row}>
            {/* Mechanic */}
            {showMechanicInfo && (
              <View
                style={[styles.fieldContainer, styles.mechanicFieldContainer]}
              >
                <LabelValue
                  label='Mechanic:'
                  value={item.mechanic.firstName + ' ' + item.mechanic.lastName}
                  numberOfLines={1}
                />
              </View>
            )}

            {/* Description */}
            <View
              style={[styles.fieldContainer, styles.descriptionFieldContainer]}
            >
              <LabelValue
                label='Description:'
                value={item.description}
                numberOfLines={1}
              />
            </View>

            {/* Status */}
            <View style={[styles.fieldContainer, styles.statusFieldContainer]}>
              <LabelValue
                label='Status:'
                value={item.status}
                numberOfLines={1}
              />
            </View>

            {/* Cost */}
            <View style={[styles.fieldContainer, styles.costFieldContainer]}>
              <LabelValue label='Cost:' value={item.cost} />
            </View>

            {/* Actions */}
            {action?.isEnabled && (
              <View
                style={[styles.fieldContainer, styles.actionFieldContainer]}
              >
                {item.status === ServiceDetailStatus.Pending && (
                  <TouchableOpacity
                    onPress={() =>
                      action.onPress(item.id, ServiceDetailStatus.Progress)
                    }
                  >
                    <MaterialIcons name='play-arrow' size={18} color='green' />
                  </TouchableOpacity>
                )}
                {item.status === ServiceDetailStatus.Progress && (
                  <TouchableOpacity
                    onPress={() =>
                      action.onPress(item.id, ServiceDetailStatus.Completed)
                    }
                  >
                    <MaterialIcons name='check' size={18} color='green' />
                  </TouchableOpacity>
                )}
              </View>
            )}
          </View>
        ))}
    </>
  )
}
