import { StyleSheet } from 'react-native'

// Styling for the component
export const styles = StyleSheet.create({
  inputContainer: {
    width: '100%',
    marginBottom: 8,
  },
  label: {
    fontSize: 16,
    color: 'rgba(28, 28, 30, 1)',
    marginBottom: 4,
  },
  readOnlyLabel: {
    fontSize: 16,
    color: 'rgba(28, 28, 30, 0.68)',
    marginBottom: 0,
  },
  input: {
    width: '100%',
    padding: 10,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
  },
  errorText: {
    color: 'red',
    marginBottom: 10,
  },
  valueLabel: {
    fontSize: 12,
    color: 'rgba(28, 28, 30, .5)',
    paddingLeft: 2,
    paddingTop: 6,
  },
})
