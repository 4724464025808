import { apiClient } from '../../apiClient'
import { RepairOrderStatus } from './types'

export const updateRepairOrderStatus = async (
  repairOrderId: string,
  status: RepairOrderStatus
): Promise<void> => {
  return apiClient.patch(`/repairOrders/${repairOrderId}/status`, {
    status,
  })
}
