import { UserScope } from '../../common/constants/user'
import { apiClient } from '../apiClient'
import { EmployeeStatus } from './employee/types'

// type Address = {
//   street: string
//   city?: string
//   country?: string
//   state?: string
//   zipCode: number
// }

// TODO move generic place
export interface Profile {
  id: string
  userId: string
  email: string
  phone: string
  firstName: string
  lastName: string
  dateOfBirth: Date
  address: string
  customerProfile: {
    id: string
  }
  companyProfile?: {
    id: string
    companyId: string
  }
  employeeProfile?: {
    id: string
    companyId: string
    status: EmployeeStatus
  }
  createdAt: Date
}

export type ProfileSummary = Pick<
  Profile,
  'email' | 'firstName' | 'lastName' | 'phone' | 'userId'
>

export type User = {
  userId: string
  currentScope?: UserScope
  roles: UserScope[]
  profile?: Profile
}

export const fetchCurrentUser = async (): Promise<User> => {
  const response = await apiClient.get('/profiles/current')

  return response.data as User
}
