import { StyleSheet } from 'react-native'
import { COLOR_WHITE } from '../../constants/colors'

// Styling for the component
export const styles = StyleSheet.create({
  container: {
    marginTop: 0,
    paddingVertical: 16,
    paddingHorizontal: 20,
    marginBottom: 0,
    backgroundColor: COLOR_WHITE,
    justifyContent: 'center', // Vertically center content
  },
})
