import React from 'react'
import { View, Text, Button } from 'react-native'
import { styles } from './styles'

export const ErrorScreen = ({ onRetry }: { onRetry?: () => void }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.errorText}>Something went wrong</Text>
      <Text style={styles.subText}>
        There was a problem connecting to the server. Please check your
        connection and try again.
      </Text>
      <Button title='Retry' onPress={() => onRetry && onRetry()} />
    </View>
  )
}
