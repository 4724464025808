import React from 'react'
import { View, Pressable, Text } from 'react-native'
import { LabelValue } from '../../../LabelValue'
import { formatVehicle } from '../../../../../utils'
import { styles } from './styles'
import { COLOR_REPAIR_ORDER_STATUS } from '../../../../constants/colors'
import type { RepairOrderInfoProps } from './types'

export function RepairOrderInfoComponent({
  repairOrder,
  action,
}: RepairOrderInfoProps) {
  return (
    <View>
      <View style={styles.statusActionContainer}>
        {/* Status */}
        <View>
          <LabelValue
            label='Status:'
            value={repairOrder.status}
            valueProps={{ color: COLOR_REPAIR_ORDER_STATUS }}
          />
        </View>

        {/* Action Button */}
        {action?.isEnabled && (
          <Pressable
            style={styles.button}
            onPress={() => action.onPress(repairOrder.id)}
          >
            <Text style={styles.buttonText}>{action.label}</Text>
          </Pressable>
        )}
      </View>

      {/* Vehicle Model */}
      <LabelValue
        label='Vehicle Model:'
        value={formatVehicle(repairOrder.vehicle)}
      />

      {/* Company Name */}
      <LabelValue label='Company Name:' value={repairOrder.company.name} />

      {/* Total Cost */}
      <LabelValue label='Total Cost:' value={repairOrder.totalCost} />

      {/* Dates */}
      <LabelValue label='CreatedAt:' value={repairOrder.createdAt} />
      <LabelValue label='UpdatedAt:' value={repairOrder.updatedAt} />
    </View>
  )
}
