import {
  RepairOrderStatus,
  RepairOrderAggregate,
} from './../../api/adapters/repairOrder/types'
import { UserScope } from '../../common/constants/user'
import { useBusinessRuleValidator } from '../utils'

export function useIsEditableByCompany(
  scope: UserScope,
  repairOrder: RepairOrderAggregate
): boolean {
  return useBusinessRuleValidator(
    scope,
    repairOrder,
    (scope, repairOrder) =>
      scope === UserScope.CompanyOwner &&
      [
        RepairOrderStatus.Pending,
        RepairOrderStatus.WaitingCustomerApproval,
      ].includes(repairOrder.status)
  )
}

export function useIsRemovableRepairOrder(
  scope: UserScope,
  repairOrder: RepairOrderAggregate
): boolean {
  return useBusinessRuleValidator(
    scope,
    repairOrder,
    (scope, repairOrder) =>
      scope === UserScope.Customer &&
      [
        RepairOrderStatus.Pending,
        RepairOrderStatus.WaitingCustomerApproval,
      ].includes(repairOrder.status)
  )
}
