const config = {
  // Dev
  //baseApiUrlMobile: 'http://192.168.211.211:3000/api/v1', // This is dynamic of your current wifi network
  //baseApiUrlWeb: 'http://127.0.0.1:3000/api/v1',

  // Local proxy to Prod
  // baseApiUrlMobile: 'http://10.0.2.2:3001/api/v1',
  // baseApiUrlWeb: 'http://127.0.0.1:3001/api/v1',

  // Prod
  baseApiUrlMobile: 'https://api.usmechanicservices.com/api/v1',
  baseApiUrlWeb: 'https://api.usmechanicservices.com/api/v1',
}

export default config
