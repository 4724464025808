// BASE COLORS
export const COLOR_AQUA_BLUE_DARK = 'rgb(8 112 140)'
export const COLOR_AQUA_BLUE = 'rgb(12 123 149)'
export const COLOR_AQUA_BLUE_LIGHT = 'rgb(10 145 174)'

export const COLOR_BLUE_DARK = 'rgb(28 26 64)'
export const COLOR_BLUE = 'rgb(12 81 141)'

export const COLOR_ORANGE = 'rgb(232 103 22)'
export const COLOR_WHITE_DARK = 'rgb(235 235 235)'
export const COLOR_WHITE = 'rgb(255 255 255)'
export const COLOR_YELLOW = 'rgb(241 173 15)'
export const COLOR_GREY = 'rgb(108 60 38)'

// BUTTON COLORS
export const COLOR_BUTTON_AQUA_BLUE = COLOR_AQUA_BLUE // Before we had '#6200ee'

// STATUS COLORS
export const COLOR_REPAIR_ORDER_STATUS = 'rgb(18 94 95)'
