import React, { useEffect, useState } from 'react'
import { View, Text, Pressable } from 'react-native'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from './schema'
import { styles } from './styles'
import { FormValues } from './type'
import { TextInputField } from '../../../common/components/TextInputField'
import { UserScope } from '../../../common/constants/user'
import { Picker } from '@react-native-picker/picker'
import {
  CompanySummary,
  fetchCompanies,
} from '../../../api/adapters/fetchCompanies'
import {
  registerProfile,
  RegisterProfilePayload,
} from '../../../api/adapters/registerProfile'
import { useAuth } from '../../../contexts/AuthContext'
import { showAlert } from '../../../common/utils/alert'
import { AxiosError } from 'axios'
import { ScrollView } from 'react-native-gesture-handler'

const defaultValues = {
  firstName: 'User First Name',
  lastName: 'User Last Name',
  dateOfBirth: '12-12-2002',
  address: 'street number, City, Zip code, Country',
  type: undefined,
  companyId: undefined,
  companyName: 'Company name 1',
  companyAddress: 'company address',
  businessRegistrationNumber: '112233',
}

const CreateProfileScreen = () => {
  const { user, loadUserData } = useAuth()
  const [companies, setCompanies] = useState<CompanySummary[]>([])
  const { control, handleSubmit, watch } = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const profileType = watch('type')

  // Fetch companies when screen loads
  useEffect(() => {
    const loadCompanies = async () => {
      try {
        const fetchedCompanies = await fetchCompanies()
        console.log('[fetchedCompanies]: ', fetchedCompanies)

        setCompanies(fetchedCompanies)
      } catch (err) {
        // TODO handle properly
        console.error('Something went wrong when fetching companies', err)
      }
    }
    loadCompanies()
  }, [])

  const onSubmit = async (data: FormValues) => {
    const payload: RegisterProfilePayload = {
      firstName: data.firstName,
      lastName: data.lastName,
      dateOfBirth: new Date(data.dateOfBirth),
      address: data.address,
      type: data.type,
    }

    if (data.type === UserScope.CompanyOwner) {
      payload.companyData = {
        name: data.companyName!,
        address: data.companyAddress!,
        businessRegistrationNumber: data.businessRegistrationNumber!,
      }
    } else if (data.type === UserScope.Employee) {
      console.log(data)
      payload.employeeData = {
        companyId: data.companyId!,
      }
    }

    try {
      await registerProfile(user!.userId, payload)
      showAlert('Success', 'Profile Registration successful!')
      await loadUserData()
    } catch (error) {
      const errorMessage = (
        (error as AxiosError).response!.data as { error: string }
      ).error
      console.error(error)

      // TODO show detailed error messages.
      const message = errorMessage.includes(
        'businessRegistrationNumber_1 dup key'
      )
        ? 'Duplicate Business Registration Number'
        : errorMessage.includes('name_1 dup key')
        ? 'Duplicate Company Name'
        : 'Internal Error, Please contact mechanicshopinc@gmail.com'

      showAlert('Error', message)
    }
  }

  return (
    <ScrollView contentContainerStyle={styles.container}>
      {/* First Name */}
      <TextInputField control={control} name='firstName' label='First Name' />

      {/* Last Name */}
      <TextInputField control={control} name='lastName' label='Last Name' />

      {/* Date of Birth */}
      <TextInputField
        control={control}
        name='dateOfBirth'
        label='Date of Birth (DD-MM-YYYY)*'
      />

      {/* Address */}
      <TextInputField control={control} name='address' label='Address' />

      {/* TODO replace with <PickerField></PickerField> */}
      {/* Type Dropdown */}
      <View style={styles.dropdown}>
        <Controller
          control={control}
          name='type'
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <>
              <Picker selectedValue={value} onValueChange={onChange}>
                <Picker.Item label='Select Profile Type' value='' />
                <Picker.Item label='Customer' value={UserScope.Customer} />
                <Picker.Item
                  label='Company Owner'
                  value={UserScope.CompanyOwner}
                />
                <Picker.Item label='Employee' value={UserScope.Employee} />
              </Picker>
              {error && <Text style={styles.errorText}>{error.message}</Text>}
            </>
          )}
        />
      </View>

      {/* TODO replace with <PickerField></PickerField> */}
      {/* Conditional Fields */}
      {profileType === UserScope.Employee && (
        <View style={styles.dropdown}>
          <Controller
            control={control}
            name='companyId'
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <Picker selectedValue={value} onValueChange={onChange}>
                  <Picker.Item label='Select Company' value='' />
                  {companies.map((company) => (
                    <Picker.Item
                      key={company.id}
                      label={company.name}
                      value={company.id}
                    />
                  ))}
                </Picker>
                {error && <Text style={styles.errorText}>{error.message}</Text>}
              </>
            )}
          />
        </View>
      )}

      {profileType === UserScope.CompanyOwner && (
        <>
          <TextInputField
            control={control}
            name='companyName'
            label='Company Name'
          />
          <TextInputField
            control={control}
            name='companyAddress'
            label='Company Address'
          />
          <TextInputField
            control={control}
            name='businessRegistrationNumber'
            label='Business Registration Number'
          />
        </>
      )}

      <Pressable style={styles.button} onPress={handleSubmit(onSubmit)}>
        <Text style={styles.buttonText}>Register</Text>
      </Pressable>
    </ScrollView>
  )
}

export default CreateProfileScreen
