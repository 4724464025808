import { StyleSheet } from 'react-native'
import { COLOR_BUTTON_AQUA_BLUE } from '../../../../constants/colors'

// Styling for the component
export const styles = StyleSheet.create({
  statusActionContainer: {
    flexDirection: 'row', // Arrange children horizontally
    alignItems: 'center', // Vertically align items
    justifyContent: 'flex-start',
  },
  button: {
    alignItems: 'center',
    backgroundColor: COLOR_BUTTON_AQUA_BLUE,
    paddingVertical: 8, // Adjust for better alignment
    paddingHorizontal: 16, // Adjust for better alignment
    borderRadius: 8,
    marginBottom: 12,
    marginLeft: 20,
  },
  buttonText: {
    color: '#fff',
    fontSize: 16,
  },
})
