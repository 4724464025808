import { StyleSheet } from 'react-native'
import {
  COLOR_BLUE_DARK,
  COLOR_BUTTON_AQUA_BLUE,
  COLOR_BLUE,
} from '../../../common/constants/colors'

// Styling for the component
export const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center', // Center the content initially
    alignItems: 'center',
    backgroundColor: '#f0f4f7',
    padding: 16,
  },
  header: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 40,
    color: COLOR_BLUE_DARK,
  },
  registerButton: {
    backgroundColor: COLOR_BUTTON_AQUA_BLUE,
    marginTop: 20,
    paddingVertical: 10,
    borderRadius: 8,
    width: 120,
    alignItems: 'center',
  },
  loginButton: {
    backgroundColor: COLOR_BLUE,
    paddingVertical: 12,
    borderRadius: 8,
    width: 120,
    alignItems: 'center',
  },
  buttonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: '600',
  },
  dropdownContainer: {
    position: 'absolute', // Position dropdown absolutely to avoid affecting layout
    top: 50, // Position it directly below the button
    width: '100%', // Dropdown items width matches button
    backgroundColor: '#fff',
    borderRadius: 8,
    elevation: 5, // Android shadow
    shadowColor: '#000', // iOS shadow settings
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 2 },
    zIndex: 2, // Ensure dropdown appears on top of everything
  },
  dropdownItem: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
  itemText: {
    fontSize: 16,
  },
})
