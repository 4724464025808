import { StyleSheet } from 'react-native'
import {
  COLOR_AQUA_BLUE_DARK,
  COLOR_BLUE_DARK,
  COLOR_WHITE,
} from '../../../../constants/colors'

// Styling for the component
export const styles = StyleSheet.create({
  collapsibleHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 12,
  },
  collapsibleHeaderLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    color: COLOR_BLUE_DARK,
  },
  row: {
    flexDirection: 'row', // Arrange children horizontally
    alignItems: 'center', // Vertically align items
    justifyContent: 'flex-start',
    borderBlockColor: COLOR_AQUA_BLUE_DARK,
    borderBottomWidth: 1,
    paddingTop: 8,
    backgroundColor: COLOR_WHITE,
  },
  fieldContainer: {
    flexShrink: 1, // Allow this item to shrink if space is tight
    flexGrow: 1, // Let it grow to take available space
    flexBasis: 'auto', // Default size is based on content
    marginRight: 12,
  },
  mechanicFieldContainer: {
    minWidth: 80,
  },
  descriptionFieldContainer: {
    minWidth: 95,
  },
  statusFieldContainer: {
    minWidth: 55,
  },
  costFieldContainer: {
    minWidth: 40,
    marginRight: 0,
  },
  actionFieldContainer: {
    width: 20,
    marginRight: 0,
    marginLeft: 12,
    alignItems: 'flex-end',
    paddingHorizontal: 8,
  },
})
