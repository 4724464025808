import { StyleSheet } from 'react-native'
import { COLOR_REPAIR_ORDER_STATUS } from '../../../../constants/colors'

// Styling for the component
export const styles = StyleSheet.create({
  section: {
    marginBottom: 8,
    padding: 10,
    backgroundColor: '#FFF',
    borderRadius: 8,
    shadowColor: '#F8F9FA',
    shadowOpacity: 0,
    shadowRadius: 8,
    elevation: 3,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  label: {
    fontSize: 18,
    color: '#888',
    width: 160,
    marginHorizontal: 6,
  },
  statusLabel: {
    fontSize: 18,
    color: COLOR_REPAIR_ORDER_STATUS,
    width: 80,
    marginHorizontal: 6,
  },
  // costLabel: {
  //   fontSize: 18,
  //   color: '#888',
  //   width: 30,
  //   marginHorizontal: 6,
  // },

  actions: { flexDirection: 'row', gap: 16 },
})
