import React, { useEffect, useState } from 'react'
import { View, Text, TouchableOpacity, FlatList, Pressable } from 'react-native'
import { MaterialIcons } from '@expo/vector-icons'
import {
  type CreateServiceDetailPayload,
  type ServiceDetailAggregate,
} from '../../../../../api/adapters/repairOrder/types'
import { styles } from './styles'
import type { PickerOption } from '../../../PickerField/type'
import { fetchCompanyEmployees } from '../../../../../api/adapters/employee/fetchCompanyEmployees'
import type { Employee } from '../../../../../api/adapters/employee/types'
import { useForm, useFieldArray } from 'react-hook-form'
import { PickerField } from '../../../PickerField'
import { TextInputField } from '../../../TextInputField'

export function CreateServiceDetailsComponent({
  companyId,
  serviceDetails = [],
  onSave,
}: {
  companyId: string
  serviceDetails?: ServiceDetailAggregate[]
  onSave: (payload: CreateServiceDetailPayload[]) => void
}) {
  const [mechanicOptions, setMechanicOptions] = useState<PickerOption[]>([])

  const loadMechanics = async (companyId: string) => {
    const mechanics: Employee[] = await fetchCompanyEmployees(companyId)
    setMechanicOptions(
      mechanics.map((e) => ({
        key: e.id,
        value: e.userId,
        label: `${e.profile?.email}`,
      }))
    )
  }

  useEffect(() => {
    loadMechanics(companyId)
  }, [])

  const { control, handleSubmit } = useForm({
    defaultValues: {
      items: serviceDetails.map((item) => ({
        cost: item.cost,
        description: item.description,
        mechanicId: item.mechanic.userId,
      })) as CreateServiceDetailPayload[],
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  })

  const onSubmit = (data: { items?: CreateServiceDetailPayload[] }) => {
    onSave(data.items!)
  }

  if (mechanicOptions.length === 0) {
    return (
      <View style={styles.container}>
        <View style={styles.centered}>
          <Text style={styles.noData}>No Mechanics Found</Text>
          <Text style={styles.noData}>
            Please, make sure the Company has Employees
          </Text>
        </View>
      </View>
    )
  }

  return (
    <>
      {fields.length > 0 && (
        <View style={styles.container}>
          <FlatList
            data={fields}
            keyExtractor={(item, index) => item.id || index.toString()}
            renderItem={({ index }) => (
              <View style={styles.row}>
                {/* Mechanic Picker */}

                <PickerField
                  control={control}
                  name={`items.${index}.mechanicId`}
                  label='Assign Mechanic'
                  options={mechanicOptions}
                />

                {/* Description */}
                <TextInputField
                  control={control}
                  name={`items.${index}.description`}
                  label='Description'
                />

                {/* Cost */}
                <TextInputField
                  control={control}
                  name={`items.${index}.cost`}
                  label='Cost'
                />

                {/* Remove Button */}
                <View style={styles.deleteButton}>
                  <TouchableOpacity onPress={() => remove(index)}>
                    <MaterialIcons name='delete' size={18} color='red' />
                  </TouchableOpacity>
                </View>
              </View>
            )}
          />
        </View>
      )}
      {/* Add Service Details Button */}
      <View style={styles.actionsContainer}>
        <Pressable
          style={styles.addButton}
          onPress={() =>
            append({
              id: Date.now().toString(),
              mechanicId: '',
              cost: 0,
              description: '',
            })
          }
        >
          <Text style={styles.buttonText}>Add Service Details</Text>
        </Pressable>

        {/* Submit Button */}
        <Pressable style={styles.submitButton} onPress={handleSubmit(onSubmit)}>
          <Text style={styles.buttonText}>Save!</Text>
        </Pressable>
      </View>
    </>
  )
}
