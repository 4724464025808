import React from 'react'
import { View, Text, TextInput } from 'react-native'
import { Controller } from 'react-hook-form'
import { styles } from './styles'
import { TextInputType } from './type'

// TODO Extend functionality to make more general
export const TextInputField = ({
  control,
  name,
  label,
  placeholder,
  editable = true,
}: TextInputType) => (
  <View style={styles.inputContainer}>
    <Text style={editable ? styles.label : styles.readOnlyLabel}>{label}</Text>
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) =>
        editable ? (
          <>
            <TextInput
              style={styles.input}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              placeholder={placeholder}
            />
            {error && <Text style={styles.errorText}>{error.message}</Text>}
          </>
        ) : (
          <Text
            numberOfLines={1}
            ellipsizeMode='tail'
            style={styles.valueLabel}
          >
            {value}
          </Text> // Render as label if not editable
        )
      }
    />
  </View>
)
