import { StyleSheet } from 'react-native'
import { COLOR_BUTTON_AQUA_BLUE } from '../../constants/colors'

// Styling for the component
export const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    padding: 16,
    backgroundColor: '#F8F9FA',
  },
  centered: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  noData: {
    fontSize: 18,
    color: '#888',
    marginBottom: 16,
  },
  button: {
    marginTop: 4,
    backgroundColor: COLOR_BUTTON_AQUA_BLUE,
    paddingVertical: 10,
    paddingHorizontal: 18,
    borderRadius: 8,
    marginBottom: 8,
  },
  buttonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: '600',
  },
})
