import React, { useEffect, useState } from 'react'
import { Text, Pressable, ScrollView } from 'react-native'
import { Vehicle } from '../../../../../api/adapters/vehicle/types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from './schema'
import { FormValues } from './type'
import { styles } from './styles'
import { TextInputField } from '../../../../../common/components/TextInputField'
import { fetchVehicleCatalogYears } from '../../../../../api/adapters/VehicleCatalog/fetchVehicleCatalogYears'
import { fetchVehicleCatalogMakes } from '../../../../../api/adapters/VehicleCatalog/fetchVehicleCatalogMakes'
import { fetchVehicleCatalogModels } from '../../../../../api/adapters/VehicleCatalog/fetchVehicleCatalogModels'
import { PickerField } from '../../../../../common/components/PickerField'
import { useFocusEffect } from '@react-navigation/native'

const defaultValues = {
  vin: '4Y1SL65848Z411439',
  licensePlate: 'SHP1234',
  color: 'black',
  mileage: '2000',
}

export function VehicleDetailComponent({
  vehicle,
  onSubmit,
}: {
  vehicle: Vehicle | null
  onSubmit: (data: FormValues) => void
}) {
  const [yearOptions, setYearOptions] = useState<number[]>([])
  const [makeOptions, setMakeOptions] = useState<string[]>([])
  const [modelOptions, setModelOptions] = useState<string[]>([])

  // React Hook Form setup
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { isSubmitting },
    watch,
  } = useForm<FormValues>({
    defaultValues: vehicle || defaultValues,
    resolver: yupResolver(schema),
    shouldUnregister: true,
  })
  // Watch the 'year' and 'make' fields
  const selectedYear = watch('year')
  const selectedMake = watch('make')
  const selectedModel = watch('model')

  const loadVehicleCatalogYears = async () => {
    setYearOptions(await fetchVehicleCatalogYears())
  }

  const onRecalculateMakes = async () => {
    if (!selectedYear) {
      setMakeOptions([])
      setValue('make', '')
      return
    }
    const makes = (await fetchVehicleCatalogMakes({ year: selectedYear })) || []
    setMakeOptions(makes)

    if (!makes.length || !selectedMake || !makes.includes(selectedMake)) {
      setValue('make', '') // Reset make to trigger Recalculate Models
      return
    }

    // Selected Make doesn't changed. Need to trigger Force Recalculate Models
    onRecalculateModels()
  }

  const onRecalculateModels = async () => {
    if (!selectedMake) {
      setModelOptions([])
      setValue('model', '')
      return
    }

    const models =
      (await fetchVehicleCatalogModels({
        year: selectedYear,
        make: selectedMake,
      })) || []
    setModelOptions(models)

    if (!models.length || !selectedModel || !models.includes(selectedModel)) {
      setValue('model', '') // Reset model
      return
    }

    // Selected Make doesn't change.
    // TODO
    // onRecalculateTrims() // Force Recalculate Trims
  }

  useFocusEffect(
    React.useCallback(() => {
      if (!vehicle) loadVehicleCatalogYears()

      return () => {
        reset()
      }
    }, [])
  )

  useEffect(() => {
    onRecalculateMakes()
  }, [selectedYear])

  useEffect(() => {
    onRecalculateModels()
  }, [selectedMake])

  // TODO <Trims> is Not implemented yet
  // useEffect(() => {
  //   onRecalculateTrims()
  // }, [selectedTrim])

  const onFormSubmit = async (data: FormValues) => {
    onSubmit(data)
  }

  return (
    <ScrollView contentContainerStyle={styles.container}>
      {/* Year */}
      <PickerField
        control={control}
        name='year'
        label='Production Year'
        options={yearOptions}
        editable={!vehicle} // Only editable if it's an Add operation
      />

      {/* Manufacturer */}
      <PickerField
        control={control}
        name='make'
        label='Manufacturer'
        options={makeOptions}
        editable={!vehicle} // Only editable if it's an Add operation
      />

      {/* Model */}
      <PickerField
        control={control}
        name='model'
        label='Model'
        options={modelOptions}
        editable={!vehicle} // Only editable if it's an Add operation
      />

      {/* VIN */}
      <TextInputField
        control={control}
        name='vin'
        label='Vehicle Identification Number (VIN)'
        editable={!vehicle} // Only editable if it's an Add operation
      />

      {/* License Plate */}
      <TextInputField
        control={control}
        name='licensePlate'
        label='License Plate'
      />

      {/* Color */}
      <TextInputField control={control} name='color' label='Color' />

      {/* Mileage */}
      <TextInputField control={control} name='mileage' label='Mileage' />

      <Pressable
        style={styles.button}
        aria-disabled={isSubmitting}
        onPress={handleSubmit(onFormSubmit)}
      >
        <Text style={styles.buttonText}>Save</Text>
      </Pressable>
    </ScrollView>
  )
}
