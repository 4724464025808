import type { Vehicle, VehicleSummary } from '../api/adapters/vehicle/types'

export function isEmpty(value: unknown): boolean {
  // Handle null and undefined
  if (value === null || value === undefined) return true

  // Handle boolean
  if (typeof value === 'boolean') return false

  // Handle numbers
  if (typeof value === 'number') return isNaN(value) || value === 0

  // Handle strings
  if (typeof value === 'string') return value.trim().length === 0

  // Handle arrays
  if (Array.isArray(value)) return value.length === 0

  // Handle objects (including Maps and Sets)
  if (typeof value === 'object') {
    if (value instanceof Map || value instanceof Set) {
      return value.size === 0
    }
    return Object.keys(value).length === 0
  }

  // For any other type (symbol, function, etc.), treat as not empty
  return false
}

export function formatVehicle(vehicle: Vehicle | VehicleSummary): string {
  return `${vehicle.make}-${vehicle.model} / ${vehicle.year}`
}
