import { apiClient } from '../../apiClient'
import { GetRepairOrdersQueryParams, RepairOrderAggregate } from './types'

export const fetchRepairOrders = async ({
  customerId,
  companyId,
  mechanicId,
  vehicleId,
  status,
}: GetRepairOrdersQueryParams): Promise<RepairOrderAggregate[]> => {
  const response = await apiClient.get('/repairOrders', {
    params: {
      customerId,
      companyId,
      mechanicId,
      vehicleId,
      status,
    },
  })

  return response.data?.items as RepairOrderAggregate[]
}
