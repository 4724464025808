import React from 'react'
import { View, Text, ScrollView, FlatList, Pressable } from 'react-native'
import { styles } from './styles'
import { RenderRepairOrderItem } from './components/RenderRepairOrderItem'
import type { RepairOrderListProps } from './types'

export function RepairOrderListComponent({
  scope,
  repairOrders,
  actions: { create, view, remove },
}: RepairOrderListProps) {
  if (repairOrders.length === 0 && !create.isEnabled) {
    return (
      <View style={styles.container}>
        <View style={styles.centered}>
          <Text style={styles.noData}>No Open Services Available</Text>
        </View>
      </View>
    )
  }

  return (
    <ScrollView style={styles.container} scrollEnabled={false}>
      <FlatList
        data={repairOrders}
        renderItem={({ item }) => (
          <RenderRepairOrderItem
            scope={scope}
            item={item}
            actions={{ remove, view }}
          />
        )}
        keyExtractor={(item) => item.id}
      />
      {create.isEnabled && (
        <View style={styles.centered}>
          <Pressable style={styles.button} onPress={create.onPress}>
            <Text style={styles.buttonText}>Open Repair Service Request!</Text>
          </Pressable>
        </View>
      )}
    </ScrollView>
  )
}
