import React from 'react'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { AppRouts, RootStackParamList } from '../../../types'
import { VehicleCatalogScreen } from '../../../../screens/private/Admin/VehicleCatalog'

const Drawer = createDrawerNavigator<RootStackParamList>()

export function LoadAdminRoutes() {
  return (
    <>
      <Drawer.Screen
        options={{
          headerShown: true,
          title: 'Vehicle Catalog Management',
        }}
        name={AppRouts.VehicleCatalog}
        component={VehicleCatalogScreen}
      />
    </>
  )
}
