import React from 'react'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { AppRouts, RootStackParamList } from '../../../types'
import { CustomerProfileScreen } from '../../../../screens/private/Customer/CustomerProfile'
import { CustomerVehiclesScreen } from '../../../../screens/private/Customer/CustomerVehicles'
import { CustomerOpenServiceRequestsScreen } from '../../../../screens/private/Customer/CustomerOpenServiceRequests'
import { CustomerServiceHistoryScreen } from '../../../../screens/private/Customer/CustomerServiceHistory'
import { CustomerVehicleDetailsScreen } from '../../../../screens/private/Customer/CustomerVehicleDetails'

const Drawer = createDrawerNavigator<RootStackParamList>()

export function LoadCustomerRoutes() {
  return (
    <>
      <Drawer.Screen
        options={{
          headerShown: true,
          title: 'Customer Profile',
        }}
        name={AppRouts.CustomerProfile}
        component={CustomerProfileScreen}
      />
      <Drawer.Screen
        options={{
          headerShown: true,
          title: 'Customer Vehicles',
        }}
        name={AppRouts.CustomerVehicles}
        component={CustomerVehiclesScreen}
      />
      <Drawer.Screen
        options={{
          headerShown: true,
          title: 'Vehicle Details',
        }}
        name={AppRouts.CustomerVehicleDetails}
        component={CustomerVehicleDetailsScreen}
      />
      <Drawer.Screen
        options={{
          headerShown: true,
          title: 'Open Service Requests',
        }}
        name={AppRouts.CustomerOpenServiceRequests}
        component={CustomerOpenServiceRequestsScreen}
      />
      <Drawer.Screen
        options={{
          headerShown: true,
          title: 'Customer Service History',
        }}
        name={AppRouts.CustomerServiceHistory}
        component={CustomerServiceHistoryScreen}
      />
    </>
  )
}
