import { apiClient } from '../../apiClient'
import { CreateRepairOrderPayload } from './types'

export const addRepairOrder = async (
  customerId: string,
  payload: CreateRepairOrderPayload
): Promise<void> => {
  return apiClient.post('/repairOrders', {
    ...payload,
    customerId,
  })
}
