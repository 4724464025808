import React, { useState } from 'react'
import { AppRouts, RepairOrderStackParam } from '../../../../navigation/types'
import { useFocusEffect, useRoute } from '@react-navigation/native'
import {
  CreateRepairOrderPayload,
  CreateServiceDetailPayload,
  RepairOrderAggregate,
  RepairOrderStatus,
} from '../../../../api/adapters/repairOrder/types'
import { useAuthenticatedUser } from '../../../../contexts/AuthContext'
import { useNavigationService } from '../../../../navigation/Service'
import { useSpinner } from '../../../../contexts/SpinnerContext'
import { fetchRepairOrder } from '../../../../api/adapters/repairOrder/fetchRepairOrder'
import { ErrorScreen } from '../../../../common/components/ErrorScreen'
import { View, Text, TouchableOpacity } from 'react-native'
import { styles } from './styles'
import { addRepairOrder } from '../../../../api/adapters/repairOrder/addRepairOrder'
import { showAlert } from '../../../../common/utils/alert'
import { AxiosError } from 'axios'
import { MaterialIcons } from '@expo/vector-icons'
import { UserScope } from '../../../../common/constants/user'
import { RepairOrderComponent } from '../../../../common/components/RepairOrder'
import { updateRepairOrderStatus } from '../../../../api/adapters/repairOrder/updateRepairOrderStatus'
import { updateServiceDetailStatus } from '../../../../api/adapters/repairOrder/updateServiceDetailStatus'
import { CreateRepairOrder } from '../../../../common/components/RepairOrder/components/CreateRepairOrder'
import { createServiceDetails } from '../../../../api/adapters/repairOrder/updateServiceDetails'
import type { RepairOrderProps } from '../../../../common/components/RepairOrder/types'

export const RepairOrderScreen = () => {
  const user = useAuthenticatedUser()
  const navigation = useNavigationService()
  const route = useRoute()
  const { startLoader, stopLoader } = useSpinner()
  const [readyToRenderKey, setReadyToRenderKey] = useState<number | null>(null)
  const [backButtonScreen, setBackButtonScreen] = useState<AppRouts>()
  /**
    scope specifies the user context in which RepairOrderScreen should be used.
    This is helpful when the screen accessed from different contexts,
    such as loading the page from both user and company perspectives.
   */
  const [scope, setScope] = useState<UserScope>()
  const [repairOrder, setRepairOrder] = useState<RepairOrderAggregate | null>(
    null
  )
  const [showErrorScreen, setShowErrorScreen] = useState(false)

  useFocusEffect(
    React.useCallback(() => {
      const { repairOrderId, backButtonScreen, pageScope } =
        route.params as RepairOrderStackParam

      setBackButtonScreen(backButtonScreen)
      setScope(pageScope || user.currentScope)

      loadRepairOrder(repairOrderId)

      return () => {
        setRepairOrder(null)
        setReadyToRenderKey(null)
        setShowErrorScreen(false)
      }
    }, [route.params])
  )

  const onBackButtonNavigate = () => {
    switch (backButtonScreen) {
      case AppRouts.CustomerOpenServiceRequests:
        return navigation.navigateCustomerOpenServiceRequests()
      default:
        return navigation.navigateCustomerProfile()
    }
  }

  const loadRepairOrder = async (repairOrderId?: string) => {
    setShowErrorScreen(false)

    if (!repairOrderId) {
      setReadyToRenderKey(Date.now())
      setRepairOrder(null)
      return
    }

    startLoader()

    try {
      const repairOrder = await fetchRepairOrder(repairOrderId)

      setRepairOrder(repairOrder)
    } catch (error) {
      console.error(
        'Something went wrong while fetching repairOrder data',
        error
      )
      setShowErrorScreen(true)
      setRepairOrder(null)
    } finally {
      stopLoader()
      setReadyToRenderKey(Date.now())
    }
  }

  const onCreate = async (payload: CreateRepairOrderPayload) => {
    try {
      if (repairOrder) {
        return console.warn('TODO: NOT implemented yet submit')
      }

      await addRepairOrder(user.userId, payload)

      showAlert('Success', 'Operation successful!')
      onBackButtonNavigate()
    } catch (error) {
      const errorMessage = (
        (error as AxiosError).response!.data as { error: string }
      ).error
      // TODO
      // const message = errorMessage.includes(
      //   'duplicate key error on field: vin_1'
      // )
      //   ? 'Duplicate RepairOrder Identification Number (VIN)'
      //   : 'Internal Error, Please contact mechanicshopinc@gmail.com'
      showAlert('Error', errorMessage)
    }
  }

  const onStatusUpdate = async (id: string, status: RepairOrderStatus) => {
    try {
      await updateRepairOrderStatus(id, status)
      showAlert('Success', 'Operation successful!')
      onBackButtonNavigate()
    } catch (error) {
      const errorMessage = (
        (error as AxiosError).response!.data as { error: string }
      ).error
      showAlert('Error', errorMessage)
    }
  }

  const onServiceDetailStatusUpdate: RepairOrderProps['onServiceDetailStatusUpdate'] =
    async ({ repairOrderId, serviceDetailId, status }) => {
      try {
        await updateServiceDetailStatus(repairOrderId, serviceDetailId, status)
        showAlert('Success', 'Operation successful!')
        loadRepairOrder(repairOrderId)
      } catch (error) {
        const errorMessage = (
          (error as AxiosError).response!.data as { error: string }
        ).error
        showAlert('Error', errorMessage)
      }
    }

  const onServiceDetailsUpdate = async (
    serviceDetails: CreateServiceDetailPayload[]
  ) => {
    try {
      await createServiceDetails(repairOrder!.id, serviceDetails)
      showAlert('Success', 'Operation successful!')
      onBackButtonNavigate()
    } catch (error) {
      const errorMessage = (
        (error as AxiosError).response!.data as { error: string }
      ).error
      showAlert('Error', errorMessage)
    }
  }

  const renderContent = () => {
    if (showErrorScreen) return <ErrorScreen />

    if (!repairOrder) {
      if (scope !== UserScope.Customer) return <></>

      return (
        <CreateRepairOrder
          customerId={user.userId}
          onCreate={onCreate}
        ></CreateRepairOrder>
      )
    }

    return (
      <>
        <RepairOrderComponent
          scope={scope!}
          repairOrder={repairOrder}
          key={readyToRenderKey}
          onStatusUpdate={onStatusUpdate}
          onServiceDetailStatusUpdate={onServiceDetailStatusUpdate}
          onServiceDetailsUpdate={onServiceDetailsUpdate}
        />
      </>
    )
  }

  if (!readyToRenderKey) return

  return (
    <>
      {/* Header */}
      <View style={styles.header}>
        <TouchableOpacity
          onPress={onBackButtonNavigate}
          style={styles.backButton}
        >
          <MaterialIcons
            name='arrow-back'
            size={20}
            color='rgba(28, 28, 30, 0.68)'
          />
        </TouchableOpacity>

        {!showErrorScreen && (
          <Text style={styles.title}>
            {!repairOrder
              ? 'Create Service Request'
              : 'Service Request Details'}
          </Text>
        )}

        {/* Placeholder for alignment if needed */}
        <View style={styles.rightPlaceholder} />
      </View>

      {renderContent()}
    </>
  )
}
