import { StyleSheet } from 'react-native'

// Styling for the component
export const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    backgroundColor: '#f8d7da',
  },
  errorText: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#721c24',
    marginBottom: 10,
  },
  subText: {
    fontSize: 14,
    color: '#721c24',
    textAlign: 'center',
    marginBottom: 20,
  },
})
