import React, { useEffect, useState } from 'react'

import { fetchVehicleCatalogManagement } from '../../../../api/adapters/VehicleCatalog/fetchVehicleCatalogManagement'
import { View, Text, ScrollView, TouchableOpacity } from 'react-native'
import { styles } from './styles'
import { MaterialIcons } from '@expo/vector-icons'
import { applyVehicleCatalogManagement } from '../../../../api/adapters/VehicleCatalog/applyVehicleCatalogManagement'
import { showAlert } from '../../../../common/utils/alert'
import { AxiosError } from 'axios'

export function VehicleCatalogScreen() {
  const [list, setList] = useState<string[]>([])

  const loadList = async () => {
    const list = await fetchVehicleCatalogManagement()
    console.log(list)
    setList(list)
  }

  useEffect(() => {
    loadList()
  }, [])

  const handleUpdate = async (name: string) => {
    try {
      await applyVehicleCatalogManagement(name)
      showAlert('Success', 'Status successfully Updated!')
    } catch (error) {
      const errorMessage = (
        (error as AxiosError).response!.data as { error: string }
      ).error
      console.error(error)
      showAlert('Error', errorMessage)
    }
  }

  if (!list.length) {
    return (
      <View style={styles.centered}>
        <Text style={styles.noText}>No List Available</Text>
      </View>
    )
  }

  return (
    <ScrollView contentContainerStyle={styles.container}>
      {list.map((item: string) => (
        <View style={styles.row} key={item}>
          <Text style={styles.column}>{item}</Text>
          <View style={styles.actionColumn}>
            <TouchableOpacity
              onPress={() => handleUpdate(item)}
              style={styles.iconButton}
            >
              <MaterialIcons name='check-circle' size={24} color='green' />
            </TouchableOpacity>
          </View>
        </View>
      ))}
    </ScrollView>
  )
}
