import React from 'react'
import { View, Text, type TextProps } from 'react-native'
import { styles } from './styles'

type ValueProps = {
  color: string
}

type LabelValueProps = {
  label: string
  value: string | number
  ellipsizeMode?: TextProps['ellipsizeMode']
  numberOfLines?: number
  valueProps?: ValueProps
}

// TODO Extend functionality to make more general
export const LabelValue = ({
  label,
  value,
  ellipsizeMode,
  numberOfLines,
  valueProps,
}: LabelValueProps) => {
  return (
    <View style={styles.container}>
      <Text style={styles.label}>{label}</Text>
      <Text
        numberOfLines={numberOfLines}
        ellipsizeMode={ellipsizeMode}
        style={[styles.value, valueProps]}
      >
        {value}
      </Text>
    </View>
  )
}
