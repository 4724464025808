import { StyleSheet } from 'react-native'

// Styling for the component
export const styles = StyleSheet.create({
  inputContainer: {
    width: '100%',
    marginBottom: 15,
  },
  label: {
    fontSize: 16,
    color: 'rgba(28, 28, 30, 1)',
    marginBottom: 4,
  },
  readOnlyLabel: {
    fontSize: 16,
    color: 'rgba(14, 14, 15, 1)',
    marginBottom: 0,
  },
  dropdown: {
    borderColor: '#ccc',
    borderRadius: 5,
  },
  errorText: {
    color: 'red',
    marginBottom: 10,
  },
  valueLabel: {
    fontSize: 14,
    color: 'rgba(28, 28, 30, .4)',
    paddingLeft: 0,
    paddingTop: 6,
  },
})
