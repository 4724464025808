import { apiClient } from '../../apiClient'
import { ServiceDetailStatus } from './types'

export const updateServiceDetailStatus = async (
  repairOrderId: string,
  serviceDetailId: string,
  status: ServiceDetailStatus
): Promise<void> => {
  return apiClient.patch(
    `/repairOrders/${repairOrderId}/serviceDetails/${serviceDetailId}/status`,
    {
      status,
    }
  )
}
