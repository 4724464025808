import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { View, Pressable, Text } from 'react-native'
import type { PickerOption } from '../../../PickerField/type'
import type { FormValues } from './type'
import { PickerField } from '../../../PickerField'
import { TextInputField } from '../../../TextInputField'
import { schema } from './schema'
import { styles } from './styles'
import { useFocusEffect } from '@react-navigation/native'
import {
  fetchCompanies,
  type CompanySummary,
} from '../../../../../api/adapters/fetchCompanies'
import { fetchVehicles } from '../../../../../api/adapters/vehicle/fetchVehicles'
import type { CreateRepairOrderPayload } from '../../../../../api/adapters/repairOrder/types'

export function CreateRepairOrder({
  customerId,
  onCreate,
}: {
  customerId: string
  onCreate: (data: CreateRepairOrderPayload) => void
}) {
  const [vehicleOptions, setVehicleOptions] = useState<PickerOption[]>([])
  const [companyOptions, setCompanyOptions] = useState<PickerOption[]>([])

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  })

  const loadCustomerVehicles = async (id: string) => {
    const vehicles = await fetchVehicles(id)
    setVehicleOptions(
      vehicles.map((v) => ({
        key: v.id,
        value: v.id,
        label: `${v.make}-${v.model} / ${v.year}`,
      }))
    )
  }

  const loadCompanies = async () => {
    const companies: CompanySummary[] = await fetchCompanies()
    setCompanyOptions(
      companies.map((c) => ({
        key: c.id,
        value: c.id,
        label: `${c.name}`,
      }))
    )
  }

  useFocusEffect(
    React.useCallback(() => {
      loadCustomerVehicles(customerId)
      loadCompanies()

      return () => {
        reset()
      }
    }, [])
  )

  return (
    <View style={styles.container}>
      <PickerField
        control={control}
        name='vehicleId'
        label='Choose Vehicle'
        options={vehicleOptions}
      />

      <PickerField
        control={control}
        name='companyId'
        label='Choose Company'
        options={companyOptions}
      />

      <TextInputField
        control={control}
        name='description'
        label='Description'
        placeholder='Enter a description for the repair order'
      />

      <Pressable
        style={styles.button}
        aria-disabled={isSubmitting}
        onPress={handleSubmit((formData) =>
          onCreate(formData as CreateRepairOrderPayload)
        )}
      >
        <Text style={styles.buttonText}>Create!</Text>
      </Pressable>
    </View>
  )
}
