import React, { useState } from 'react'
import {
  View,
  Text,
  // ScrollView,
  // TouchableOpacity,
  Pressable,
  FlatList,
  TouchableOpacity,
} from 'react-native'
import { MaterialIcons } from '@expo/vector-icons'
import { Vehicle } from '../../../../api/adapters/vehicle/types'
import { fetchVehicles } from '../../../../api/adapters/vehicle/fetchVehicles'
import { useAuthenticatedUser } from '../../../../contexts/AuthContext'
import { styles } from './styles'
import { useNavigationService } from '../../../../navigation/Service'
import { useFocusEffect } from '@react-navigation/native'
import { ScrollView } from 'react-native-gesture-handler'
import { deleteVehicle } from '../../../../api/adapters/vehicle/deleteVehicle'
import { showAlert, showAlertConfirm } from '../../../../common/utils/alert'
import { AxiosError } from 'axios'

export function CustomerVehiclesScreen() {
  const [vehicles, setVehicles] = useState<Vehicle[]>([])
  const user = useAuthenticatedUser()
  const navigation = useNavigationService()

  useFocusEffect(
    React.useCallback(() => {
      loadVehicles()
    }, [])
  )

  const handleDelete = async ({ id }: Vehicle) => {
    showAlertConfirm(
      'Delete Item',
      'Are you sure you want to delete this item?',
      async () => {
        // When confirmed
        try {
          await deleteVehicle(id)
          showAlert('Success', 'Vehicle successfully Deleted!')
          loadVehicles()
        } catch (error) {
          const errorMessage = (
            (error as AxiosError).response!.data as { error: string }
          ).error
          console.error(error)
          showAlert('Error', errorMessage)
        }
      }
    )
  }

  const loadVehicles = async () => {
    try {
      const data = await fetchVehicles(user.userId!)
      setVehicles(data)
    } catch (err) {
      // TODO handle properly
      console.error('Something went wrong when fetching vehicles data', err)
    }
  }

  const renderVehicle = ({ item }: { item: Vehicle }) => (
    <View style={styles.section}>
      <View style={styles.vehicleRow}>
        <TouchableOpacity
          onPress={() =>
            navigation.navigateCustomerVehicleDetails({ vehicleId: item.id })
          }
        >
          <Text style={styles.label}>
            {item.make}-{item.model} / {item.year}
          </Text>
        </TouchableOpacity>
        <View style={styles.actions}>
          <TouchableOpacity
            onPress={() =>
              navigation.navigateCustomerVehicleDetails({ vehicleId: item.id })
            }
          >
            <MaterialIcons name='edit' size={18} color='blue' />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => handleDelete(item)}>
            <MaterialIcons name='delete' size={18} color='red' />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  )

  return (
    <ScrollView style={styles.container} scrollEnabled={false}>
      {vehicles.length ? (
        // <Text style={styles.section}>
        // <View style={styles.section}>
        <FlatList
          data={vehicles}
          renderItem={renderVehicle}
          keyExtractor={(item) => item.id}
        />
      ) : (
        // </View>
        // </Text>
        <View style={styles.centered}>
          <Text style={styles.noData}>No Vehicles Available</Text>
        </View>
      )}

      <View style={styles.centered}>
        <Pressable
          style={styles.button}
          onPress={() => navigation.navigateCustomerVehicleDetails({})}
        >
          <Text style={styles.buttonText}>Add Vehicle</Text>
        </Pressable>
      </View>
    </ScrollView>
  )
}
