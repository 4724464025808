import React, { useMemo } from 'react'
import { View, Text } from 'react-native'
import { Controller } from 'react-hook-form'
import { styles } from './styles'
import { PickerType } from './type'
import { Picker } from '@react-native-picker/picker'

// TODO Extend functionality to make more general
export const PickerField = ({
  control,
  name,
  label,
  placeholder = '----',
  options,
  editable = true,
}: PickerType) => {
  // Determine item structure (string, number, boolean, or object with `key` property)
  const itemStructure = useMemo(() => {
    if (options.length > 0) {
      const firstItem = options[0]
      if (
        typeof firstItem === 'string' ||
        typeof firstItem === 'number' ||
        typeof firstItem === 'boolean'
      ) {
        return 'primitive' // All items are simple types
      }
      if (
        typeof firstItem === 'object' &&
        firstItem !== null &&
        'key' in firstItem &&
        'value' in firstItem &&
        'label' in firstItem
      ) {
        return 'object' // All items are objects with a key, value and label
      }
    }
    return 'unknown'
  }, [options])

  return (
    <View style={styles.inputContainer}>
      <Text style={editable ? styles.label : styles.readOnlyLabel}>
        {label}
      </Text>
      <View style={styles.dropdown}>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value }, fieldState: { error } }) =>
            editable ? (
              <>
                <Picker selectedValue={value} onValueChange={onChange}>
                  <Picker.Item label={label} value={placeholder} />
                  {options.map((option, index) => {
                    let itemKey = `${index}`
                    let displayLabel = ''
                    let itemValue = option

                    if (itemStructure === 'primitive') {
                      displayLabel = String(option)
                    } else if (
                      itemStructure === 'object' &&
                      option &&
                      typeof option === 'object'
                    ) {
                      itemKey = option.key
                      displayLabel = option.label
                      itemValue = option.value
                    }

                    return (
                      <Picker.Item
                        key={itemKey}
                        label={displayLabel}
                        value={itemValue}
                      />
                    )
                  })}
                </Picker>
                {error && <Text style={styles.errorText}>{error.message}</Text>}
              </>
            ) : (
              <Text style={styles.valueLabel}>{value}</Text> // Render as label if not editable
            )
          }
        />
      </View>
    </View>
  )
}
