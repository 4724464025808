import React from 'react'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { AppRouts, RootStackParamList } from '../../../types'
import { UserScope } from '../../../../common/constants/user'
import { NotFoundScreen } from '../../../../screens/public/404'
import { ProfileRegistrationRoute } from './ProfileRegistrationRoute'
import { LoadCustomerRoutes } from './LoadCustomerRoutes'
import { LoadCompanyRoutes } from './LoadCompanyRoutes'
import { LoadEmployeeRoutes } from './LoadEmployeeRoutes'
import { LoadAdminRoutes } from './LoadAdminRoutes'
import { LoadCommonRoutes } from './CommonRoutes'

const Drawer = createDrawerNavigator<RootStackParamList>()

export function PrivateRoutes(currentScope: UserScope) {
  switch (currentScope) {
    case undefined:
      return ProfileRegistrationRoute()
    case UserScope.Admin:
      return LoadAdminRoutes()
    case UserScope.Customer:
      return (
        <>
          {LoadCustomerRoutes()}
          {LoadCommonRoutes()}
        </>
      )
    case UserScope.CompanyOwner:
      return (
        <>
          {LoadCustomerRoutes()}
          {LoadCommonRoutes()}
          {LoadCompanyRoutes()}
        </>
      )
    case UserScope.Employee:
      return (
        <>
          {LoadCustomerRoutes()}
          {LoadCommonRoutes()}
          {LoadEmployeeRoutes()}
        </>
      )
    default:
      return (
        <Drawer.Screen
          options={{
            headerShown: true,
            title: 'Not Found',
          }}
          name={AppRouts.NotFound}
          component={NotFoundScreen}
        />
      )
  }
}
