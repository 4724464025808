import React, { useState } from 'react'
import { useFocusEffect, useRoute } from '@react-navigation/native'
import { AppRouts, RootStackParamList } from '../../../../navigation/types'
import { Vehicle } from '../../../../api/adapters/vehicle/types'
import { fetchVehicle } from '../../../../api/adapters/vehicle/fetchVehicle'
import { useSpinner } from '../../../../contexts/SpinnerContext'
import { VehicleDetailComponent } from './components'
import { showAlert } from '../../../../common/utils/alert'
import { useNavigationService } from '../../../../navigation/Service'
import { FormValues } from './components/type'
import { addVehicle } from '../../../../api/adapters/vehicle/addVehicle'
import { AxiosError } from 'axios'
import { useAuthenticatedUser } from '../../../../contexts/AuthContext'
import { updateVehicle } from '../../../../api/adapters/vehicle/updateVehicle'
import { ErrorScreen } from '../../../../common/components/ErrorScreen'
import { TouchableOpacity, View, Text } from 'react-native'
import { MaterialIcons } from '@expo/vector-icons'
import { styles } from './styles'

export function CustomerVehicleDetailsScreen() {
  const user = useAuthenticatedUser()
  const navigation = useNavigationService()
  const route = useRoute()
  const { startLoader, stopLoader } = useSpinner()
  const [readyToRenderKey, setReadyToRenderKey] = useState<number | null>(null)
  const [vehicle, setVehicle] = useState<Vehicle | null>(null)
  const [showErrorScreen, setShowErrorScreen] = useState(false)

  useFocusEffect(
    React.useCallback(() => {
      const vehicleId = (
        route.params as RootStackParamList[AppRouts.CustomerVehicleDetails]
      )?.vehicleId

      loadVehicle(vehicleId)

      return () => {
        setVehicle(null)
        setReadyToRenderKey(null)
        setShowErrorScreen(false)
      }
    }, [route.params])
  )

  const loadVehicle = async (vehicleId?: string) => {
    setShowErrorScreen(false)

    if (!vehicleId) {
      setReadyToRenderKey(Date.now())
      setVehicle(null)
      return
    }

    startLoader()

    try {
      const vehicle = await fetchVehicle(vehicleId)

      setVehicle(vehicle)
    } catch (error) {
      console.error('Something went wrong while fetching vehicle data', error)
      setShowErrorScreen(true)
      setVehicle(null)
    } finally {
      stopLoader()
      setReadyToRenderKey(Date.now())
    }
  }

  const onSubmit = async (data: FormValues) => {
    try {
      if (vehicle) {
        await updateVehicle(vehicle.id, {
          customerId: user.userId,
          licensePlate: data.licensePlate,
          color: data.color,
          mileage: data.mileage,
        } as Vehicle)
      } else {
        await addVehicle(user.userId, data as Vehicle)
      }

      showAlert('Success', 'Operation successful!')
      navigation.navigateCustomerVehicles()
    } catch (error) {
      const errorMessage = (
        (error as AxiosError).response!.data as { error: string }
      ).error
      const message = errorMessage.includes(
        'duplicate key error on field: vin_1'
      )
        ? 'Duplicate Vehicle Identification Number (VIN)'
        : 'Internal Error, Please contact mechanicshopinc@gmail.com'

      showAlert('Error', message)
    }
  }

  if (!readyToRenderKey) return

  return (
    <>
      {/* Header */}
      <View style={styles.header}>
        <TouchableOpacity
          onPress={() => navigation.navigateCustomerVehicles()}
          style={styles.backButton}
        >
          <MaterialIcons
            name='arrow-back'
            size={20}
            color='rgba(28, 28, 30, 0.68)'
          />
        </TouchableOpacity>

        {!showErrorScreen && (
          <Text style={styles.title}>
            {!vehicle ? 'Add' : 'Update'} Vehicle
          </Text>
        )}

        {/* Placeholder for alignment if needed */}
        <View style={styles.rightPlaceholder} />
      </View>

      {showErrorScreen && <ErrorScreen />}

      {!showErrorScreen && (
        <VehicleDetailComponent
          vehicle={vehicle}
          key={readyToRenderKey}
          onSubmit={onSubmit}
        />
      )}
    </>
  )
}
