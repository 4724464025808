import { StyleSheet } from 'react-native'

// Styling for the component
export const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    padding: 16,
    backgroundColor: '#F8F9FA',
  },
  section: {
    marginBottom: 8,
    padding: 10,
    backgroundColor: '#FFF',
    borderRadius: 8,
    shadowColor: '#F8F9FA',
    shadowOpacity: 0,
    shadowRadius: 8,
    elevation: 3,
  },
  vehicleRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  label: {
    fontSize: 18,
    color: '#888',
  },

  centered: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  noData: {
    fontSize: 18,
    color: '#888',
    marginBottom: 16,
  },

  button: {
    marginTop: 4,
    backgroundColor: '#7300ee',
    paddingVertical: 10,
    paddingHorizontal: 18,
    borderRadius: 8,
    marginBottom: 8,
  },
  buttonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: '600',
  },

  // container: { padding: 16 },
  actions: { flexDirection: 'row', gap: 16 },
})
