import { apiClient } from '../../apiClient'
import { VehicleCatalogQueryOptions } from './types'

export const fetchVehicleCatalogMakes = async ({
  year,
}: Pick<VehicleCatalogQueryOptions, 'year'>): Promise<string[]> => {
  const response = await apiClient.get(`/vehicleCatalog/makes`, {
    params: {
      year,
    },
  })
  return response.data as string[]
}
