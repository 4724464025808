import { apiClient } from './apiClient'

let requestInterceptorId: number | null = null
let responseInterceptorId: number | null = null

export const setupInterceptors = (authToken: string, logout: () => void) => {
  // Eject previous interceptors if they exist
  if (requestInterceptorId !== null) {
    apiClient.interceptors.request.eject(requestInterceptorId)
  }
  if (responseInterceptorId !== null) {
    apiClient.interceptors.response.eject(responseInterceptorId)
  }

  // Add new request interceptor
  requestInterceptorId = apiClient.interceptors.request.use(
    (config) => {
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  // Add new response interceptor
  responseInterceptorId = apiClient.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      const { response } = error
      if (response?.status === 401) {
        console.log('GOT 401 -> CALLING LOGOUT')
        logout()
      } else if (response?.status === 403) {
        console.log('GOT 403')
      }
      return Promise.reject(error)
    }
  )
}
