import { StyleSheet } from 'react-native'
import { COLOR_BLUE_DARK } from '../../../common/constants/colors'

// Styling for the component
export const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center', // Vertically center content
    alignItems: 'center', // Horizontally center content
    padding: 20,
  },
  title: {
    fontSize: 24,
    marginBottom: 20,
    color: COLOR_BLUE_DARK,
    fontWeight: '500',
  },
  subTitle: {
    fontSize: 22,
    color: '#042904',
    marginBottom: 20,
  },
  inputContainer: {
    width: '100%',
    marginBottom: 15,
  },
  input: {
    width: '100%',
    padding: 10,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
  },
  error: {
    color: 'red',
    marginTop: 5,
  },
})
