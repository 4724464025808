import { useMemo } from 'react'

type BusinessRuleValidator<TEnum, TObject> = (
  enumValue: TEnum,
  objectValue: TObject
) => boolean

export const useBusinessRuleValidator = <TEnum, TObject>(
  enumValue: TEnum,
  objectValue: TObject,
  validator: BusinessRuleValidator<TEnum, TObject>
): boolean => {
  // Serialize the object to detect deep changes
  const objectHash = JSON.stringify(objectValue)

  // Use memoization to recompute only when enumValue or objectHash changes
  const result = useMemo(() => {
    return validator(enumValue, objectValue)
  }, [enumValue, objectHash]) // Dependency array tracks deep changes for object

  return result
}
