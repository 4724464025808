import React from 'react'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { AppRouts, RootStackParamList } from '../../../types'
import { RepairOrderScreen } from '../../../../screens/private/Common/RepairOrder'

const Drawer = createDrawerNavigator<RootStackParamList>()

export function LoadCommonRoutes() {
  return (
    <Drawer.Screen
      options={{
        headerShown: true,
        title: 'Service Request Details',
      }}
      name={AppRouts.RepairOrder}
      component={RepairOrderScreen}
    />
  )
}
