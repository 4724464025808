import React from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import { MaterialIcons } from '@expo/vector-icons'
import { styles } from './styles'
import { UserScope } from '../../../../constants/user'
import type { RepairOrderListItemProps } from '../../types'

export function RenderRepairOrderItem({
  item,
  scope,
  actions: { remove, view },
}: RepairOrderListItemProps) {
  return (
    <View style={styles.section}>
      <View style={styles.row}>
        <TouchableOpacity onPress={() => view.onPress(item)}>
          <View style={styles.row}>
            {/* Status  */}

            <Text
              numberOfLines={1}
              ellipsizeMode='head'
              style={styles.statusLabel}
            >
              {item.status}
            </Text>

            {/* Vehicle Info */}
            <Text numberOfLines={1} ellipsizeMode='head' style={styles.label}>
              {item.vehicle.model}-{item.vehicle.make} / {item.vehicle.year}
            </Text>

            <Text numberOfLines={1} ellipsizeMode='head' style={styles.label}>
              {scope === UserScope.CompanyOwner
                ? item.customer?.firstName
                : item.company?.name}
            </Text>

            {/* Total Cost */}
            {/* <Text style={styles.costLabel}>{item.totalCost}</Text> */}
          </View>
        </TouchableOpacity>
        <View style={styles.actions}>
          {remove.isEnabled(item) && (
            <TouchableOpacity onPress={() => remove.onPress(item)}>
              <MaterialIcons name='delete' size={18} color='red' />
            </TouchableOpacity>
          )}
          <TouchableOpacity onPress={() => view.onPress(item)}>
            <MaterialIcons name='edit-note' size={18} color='blue' />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  )
}
