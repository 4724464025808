import { StyleSheet } from 'react-native'
import { COLOR_BUTTON_AQUA_BLUE } from '../../../../../common/constants/colors'

// Styling for the component
export const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    marginTop: 0,
    padding: 20,
    marginBottom: 0,
    backgroundColor: '#fff',
    justifyContent: 'center', // Vertically center content
    alignItems: 'center', // Horizontally center content
  },
  button: {
    backgroundColor: COLOR_BUTTON_AQUA_BLUE,
    paddingVertical: 12,
    paddingHorizontal: 40,
    borderRadius: 8,
    marginBottom: 30,
  },
  buttonText: {
    color: '#fff',
    fontSize: 16,
  },
})
